import styled from 'styled-components'

export const ContentSection = styled.section`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 6.4rem 6.4rem;
`

export const ContentTitle = styled.h2`
  margin: 0;
  padding: 0.4rem 0.8rem;
  font-weight: 600;
  font-size: 2.4rem;
  background-color: ${({ theme }) => theme.primary};
  display: inline-block;
`

export const FieldSection = styled.section`
  margin: 4.8rem 0 0;
  max-width: 896px;
`

export const FieldTitle = styled.h3`
  margin: ${({ inside }) => (inside ? '1.2rem 0 0.4rem' : '0 0 2rem 0')};
  font-weight: 600;
  font-size: ${({ inside }) => (inside ? '1.6rem' : '2rem')};
`

export const FieldParagraph = styled.p`
  margin: 0 0 0.8rem;
  padding-bottom: 0;
  padding-left: ${({ listItem }) => (listItem ? '2rem' : 0)};
  font-weight: 400;
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  font-size: 1.6rem;
  text-align: justify;
  position: relative;
  &::before {
    position: absolute;
    top: 0.75rem;
    left: 0;
    content: '';
    display: ${({ listItem }) => (listItem ? 'block' : 'none')};
    height: 0.8rem;
    width: 0.8rem;
    background-color: ${({ theme }) => theme.primary};
    border-radius: 50%;
  }
`
export const LinkToFile = styled.a`
  color: ${({ theme }) => theme.black};
  &:hover {
    background-color: ${({ theme }) => theme.primary};
    text-decoration: none;
    cursor: pointer;
  }
`
export const Field2Columns = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(
    ${({ listLength }) => Math.ceil(listLength / 2)},
    1fr
  );
`
