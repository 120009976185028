import React from 'react'
import SEO from 'components/SEO/SEO'
import {
  ContentSection,
  ContentTitle,
  FieldSection,
  FieldTitle,
  FieldParagraph,
} from 'components/StyledComponents/StyledComponents'
import { Field2Columns } from '../components/StyledComponents/StyledComponents'

const IndexPage = () => (
  <>
    <SEO title="About" />
    <ContentSection>
      <ContentTitle>About</ContentTitle>
      <FieldSection>
        <FieldTitle>Basic information</FieldTitle>
        <FieldParagraph listItem>Gold Open Access Journal</FieldParagraph>
        <FieldParagraph listItem>
          An international peer-reviewed journal published under the auspices of
          the European Rural Development Network – international and
          interdisciplinary rural research community.
        </FieldParagraph>
        <FieldParagraph listItem>Published since 2003.</FieldParagraph>
        <FieldParagraph listItem>
          The journal is administered by Editorial Team and an international
          Editorial Board
        </FieldParagraph>
        <FieldParagraph listItem>
          Editor-in-Chief: Dr Paweł Chmieliński
        </FieldParagraph>
        <FieldParagraph listItem>
          The journal is published yearly
        </FieldParagraph>
      </FieldSection>
      <FieldSection>
        <FieldTitle>Aims & Scope</FieldTitle>
        <FieldParagraph>
          The 'Rural Areas and Development' Yearbook was established in 2003 as
          a forum for the exchange of scientific ideas and research experience
          for researchers from Europe, with particular emphasis on Central and
          Eastern European countries, both members of the European Union and
          associated and candidate countries of the Eastern Partnership and the
          Western Balkans. The integrating dimension of European rural
          development researchers is the journal's overarching objective, but we
          strongly invite high quality research from around the world. The
          journal publishes articles related to research on rural development,
          socio-economic and demographic structures of rural communities, family
          farming, food systems as well as regional, local studies and community
          development.
        </FieldParagraph>
        <FieldParagraph>
          The journal general focus is related to public policy and
          institutional environment of rural areas, including analyses of state
          policy instruments aimed at improving the economic, social and
          environmental situation. The rural community, space and economy
          constitute the main axis of research presented in the journal. A great
          emphasis on interdisciplinarity results from the need to go beyond the
          state of the art of rural science, where agriculture still plays a
          significant role, but is an element of a broad and complex system.
        </FieldParagraph>
        <FieldParagraph>
          Our authors represent various fields of research (although not limited
          to): economics, sociology, political science, economic geography,
          regional studies. The interdisciplinary approach to rural areas and
          development processes, as well as the international group of people
          involved in the journal, create its unique position in the European
          Research Area.
        </FieldParagraph>
        <FieldParagraph>
          The language of the journal is English. Articles are published with
          the highest standards of publication in a double-blending peer-review
          system, which is supervised by the Editorial Team and the Editorial
          Board. The journal has clearly defined criteria concerning ethical
          principles and rules related to maintaining high standards of honesty
          in the approach to conducting research and publishing its results.
        </FieldParagraph>
        <FieldParagraph>
          All articles are published in gold open access and are immediately and
          permanently free for everyone to read and download. We offer authors a
          user license Creative Commons Attribution (CC BY), which define the
          permitted reuse of articles. There is no Publication Fee, the journal
          is funded by publishers.
        </FieldParagraph>
      </FieldSection>
      <FieldSection>
        <FieldTitle>Editorial Team</FieldTitle>
        <Field2Columns listLength={2}>
          <div>
            <FieldTitle inside>Editor-in-Chief</FieldTitle>
            <FieldParagraph listItem>Paweł Chmieliński</FieldParagraph>
            <FieldTitle inside>Methodology Editors</FieldTitle>
            <FieldParagraph listItem>Marcin Gospodarowicz</FieldParagraph>
            <FieldParagraph listItem>Aleksandra Pawłowska</FieldParagraph>
            <FieldTitle inside>Technical Editor-in-Chief</FieldTitle>
            <FieldParagraph listItem>Leszek Ślipski</FieldParagraph>
            <FieldTitle inside>Proofreading</FieldTitle>
            <FieldParagraph listItem>Katarzyna Mikulska</FieldParagraph>
          </div>
          <div>
            <FieldTitle inside>Vice Editor-in-Chief</FieldTitle>
            <FieldParagraph listItem>Konrad Czapiewski</FieldParagraph>
            <FieldTitle inside>Publishing Editors</FieldTitle>
            <FieldParagraph listItem>Krzysztof Kossakowski</FieldParagraph>
            <FieldParagraph listItem>Barbara Pawłowska</FieldParagraph>
            <FieldTitle inside>Abstracting and Indexing</FieldTitle>
            <FieldParagraph listItem>Kamila Tomaszewska</FieldParagraph>
            <FieldTitle inside>IT Management</FieldTitle>
            <FieldParagraph listItem>Daniel Maniszewski</FieldParagraph>
          </div>
        </Field2Columns>
      </FieldSection>
      <FieldSection>
        <FieldParagraph italic>
          PUBLISHED by the European Rural Development Network and the Institute
          of Agricultural and Food Economics – National Research Institute,
          Warsaw, Poland.
        </FieldParagraph>
        <FieldParagraph italic>
          COPYRIGHT by the European Rural Development Network.
        </FieldParagraph>
      </FieldSection>
    </ContentSection>
  </>
)

export default IndexPage
